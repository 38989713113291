import React from "react"
import { graphql } from "gatsby"
import { Page } from "../../components/elements"
import { Helmet } from "react-helmet"
import { Template } from "./Template"

export default function Main({
    data,
    data: {
        markdownRemark: {
            frontmatter: { title, excerpt },
        },
    },
}) {
    return (
        <Page title={title} description={excerpt}>
            <Helmet>
                <style type="text/css">
                    {`
                    .right .gatsby-resp-image-wrapper {
                        float: right;
                        width: 100%;
                        margin: 20px !important;
                    }
                    
                    .left .gatsby-resp-image-wrapper {
                        float: left;
                        width: 100%;
                        margin: 20px !important;
                    }  
                    .gatsby-resp-image-wrapper{
                        margin-left:0 !important;
                    }  
                    
                    `}
                </style>
            </Helmet>
            <Template data={data} />
        </Page>
    )
}

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            ...NewsFields
        }
    }
`
