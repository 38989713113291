import React from "react"
import { Button } from "react-bootstrap"
import { PageCenter } from "../../components/elements/wrappers/PageCenter.jsx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Markdown } from "../../components/tools/Markdown.js"
import { removeLocalPath } from "../../cms/previews/tools/removeLocalPath.js"

const imageStyles = { float: "left", margin: "20px" }

export function Template({
    data: {
        markdownRemark: {
            html,
            frontmatter: {
                title,
                author,
                date,
                featuredImage,
                inline_image,
            } = {},
        } = {},
    },
}) {
    const image = getImage(featuredImage)
    return (
        <div className="news-page">
            <PageCenter>
                <h4 style={{ color: "var(--red2)" }}>{title}</h4>
                <p className="m-0">By {author}</p>
                <p>
                    <span>{date}</span>
                    <Button
                        onClick={() => window.print()}
                        variant="white"
                        style={{
                            float: "right",
                            textDecoration: "underline",
                            outline: "none",
                            boxShadow: "none",
                        }}
                    >
                        Print Article
                    </Button>
                </p>
                <hr />
                {inline_image ? (
                    !image ? (
                        <img
                            src={removeLocalPath(featuredImage)}
                            alt="featureImage"
                            style={imageStyles}
                        />
                    ) : (
                        <GatsbyImage
                            image={image}
                            alt={title}
                            style={imageStyles}
                        />
                    )
                ) : null}
                <Markdown>{html}</Markdown>
                <hr />
            </PageCenter>
        </div>
    )
}
